import $ from 'jquery';


export default class SlickSlider {
    init() {
        $('[data-slick]').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            let settings = {
                prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><i class=\"material-icons left\">arrow_back</i></button>",
                nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><i class=\"material-icons right\">arrow_forward</i></button>"
            };

            if(self.attr('id') === 'homepage-slider') {
                settings = {
                    customPaging: function (slider, i) {
                        let $slide = $(slider.$slides[i]).find('[data-title]');
                        return '<a class="pager__item"><span class="line1">' + $slide.data('title') + '</span> <span class="line2">' + $slide.data('legend') + '</span></a>';
                    }
                };
            }

            self.slick(settings);
        });
    }

}
